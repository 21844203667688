import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import SEO from '../components/seo';
import Button from '../components/button';

const fullConfig = resolveConfig(tailwindConfig);

const Section = tw.section``;

const About = ({ data, location }) => {
  const parentTitle = '';
  const pageTitle = 'About';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <div css={tw`bg-maroon`}>
        <BackgroundImage
          fluid={data.homeHero.childImageSharp.fluid}
          className="container"
          css={[tw`relative bg-maroon bg-right`, `background-size: auto 140%;`]}
        >
          <div css={[tw`flex flex-col`, `min-height:70vh`]}>
            <div css={tw`pt-10 pb-5 md:py-0 my-auto`}>
              <h1
                className="text-shadow-lg"
                css={[
                  tw`mb-5 w-1/2 text-white text-left tracking-wider underline`,
                  `text-decoration-color: ${fullConfig.theme.colors.gold.default}`,
                ]}
              >
                Social justice consciousness, scholarship and collaboration
              </h1>             
            </div>
            <div
              css={tw`-mb-16 px-36 py-5 text-center rounded-lg border-t-8 border-gold bg-white shadow-lg`}
            >
              <h2
                css={tw`px-5 md:px-0 text-xl uppercase tracking-wider font-normal`}
              >
                A globally respected social justice research and training hub
              </h2>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <Section css={tw`pt-20 pb-10`}>
        <div className="container">
          <h2>Overview</h2>
          <LeadText>
            The Centre for Social Justice was established in 2016. It is
            headed by Prof Thuli Madonsela, the former Public Protector of South
            Africa, and is anchored in the triple objectives of responsive
            research, teaching and social impact.
          </LeadText>
          <p>
            Social justice is said to be “the way in which human rights are
            manifested in the everyday lives of people at every level of
            society” (Edmund Rice Centre, 2000, p. 1). The working definition
            underpinning the research of the Chair is that Social justice is
            about the equal enjoyment of all rights and freedoms regardless of
            human diversity reflected in the fair and just and distribution of
            all opportunities, benefits, privileges and burdens in a society or
            group. The Social Justice M-Plan is the flagship programme of Law
            Trust the Chair in Social Justice. It is also an avenue for giving
            effect to the University’s social impact objective, particularly
            with regard to the grand constitutional objective of healing the
            divisions of the past and transforming structural social relations
            in society to give effect to the constitutional promise of freeing
            the potential and improving the quality of life of every citizen.
            Influenced by 2 years of intensive research and modelled on the US
            sponsored post-World World 2 Europe Recovery Marshal Plan, the
            Social Justice M-Plan is an integrated ground up civil society plan
            that seeks to coordinate systematic and integrated academic,
            business and broader civil society input to support government
            efforts towards breaking the back of poverty and inequality. Worth
            noting, is that the research includes auditing and integrating
            existing social justice research and data while generating new
            knowledge and information where necessary. Teaching on the other
            hand, extends to fostering appropriate knowledge, skills and values
            among decision-makers that drive public policy, planning and
            monitoring.{' '}
          </p>
        </div>
      </Section>
      <Section css={tw`py-20 bg-maroon`}>
        <div className="container">
          <h2 css={tw`mt-0 text-white`}>Core Strategic Outcomes</h2>
          <Grid>
            <div css={tw`p-5 bg-white rounded-lg shadow-lg`}>
              <h3>Academic scholarship</h3>
              <LeadText>
                ...to anchor social justice as an academic discipline
              </LeadText>
            </div>
            <div
              className="md:col-span-2"
              css={tw`p-5 bg-white rounded-lg shadow-lg`}
            >
              <h3>Social impact in society through the M-Plan</h3>
              <LeadText>
                ...to catalyse social and economic inclusion through research
                for impact and purposeful partnerships
              </LeadText>
            </div>
            <div css={tw`p-5 bg-white rounded-lg shadow-lg`}>
              <h3>Social impact in Stellenbosch University</h3>
              <LeadText>
                ...to support the achievement of a transformative student
                experience and employer of choice
              </LeadText>
            </div>
            <div css={tw`p-5 bg-white rounded-lg shadow-lg`}>
              <h3>Sustainability</h3>
              <LeadText>
                ...to contribute to building a thriving Stellenbosch University
              </LeadText>
            </div>
            <div css={tw`p-5 bg-white rounded-lg shadow-lg`}>
              <h3>
                Globally recognised Social Justice hub and centre of excellence
              </h3>
              <LeadText>...to do research for impact</LeadText>
            </div>
          </Grid>
        </div>
      </Section>
      <Section css={tw`py-20`}>
        <div className="container">
          <Img
            fixed={data.profMadonsela.childImageSharp.fixed}
            alt="Prof. Thuli Madonsela"
            css={tw`rounded-lg md:float-right max-w-full md:ml-10 md:mb-10`}
          />
          <h2>
            Prof. Thuli Madonsela <br />
            <small css={tw`text-dark-gray font-normal`}>
              Director of the Centre for Social Justice, Stellenbosch University
            </small>
          </h2>
          <p>Professor Thuli (Thulisile) Madonsela is a professor of law occupying the Law Trust Research Chair in Social Justice at Stellenbosch University. She is also a member of the African Academy of Sciences and founder of the Thuma Foundation for Democracy Leadership and Literacy a Member of the African Academy of Sciences, a 2017 Harvard Advanced Leadership Fellow, and a One Young World Counsellor. The former Public Protector of South Africa and full-time Law Commissioner, Professor Madonsela was one of the drafters of the South African Constitution and a co-architect and drafter of several constitutionally mandated laws, including the Equality Act, Employment Equity Act and Promotion of Administrative Justice Act.</p><p>She has an extensive background in applied constitutional and administrative law principles mainly based on her public service years and her investigation of improper conduct in state affairs as Public Protector. She helped draft several international human rights instruments and country reports. She currently teaches Constitutional Law, Social Justice Law, Administrative Law, and Constitutional Governance and Ethical Leadership and has written and published extensively on these matters. An advocate of the High Court of South Africa, Professor Madonsela holds eight honorary law doctorates in addition to her normal law degrees and over 70 awards including the German Africa Award, A French Knighthood and Time 100 Most Influential People in The World (2014). Professor Madonsela is a mother of two, an avid mountaineer who has summited Mt Kilimanjaro twice under the #Trek4Mandela campaign and for the Social Justice M-Plan and regularly hikes for the #Action4Inclusion campaign, a quest to end student debt.</p>
          <h2>Featured works</h2>
          <Grid>
            <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
              <h3 css={tw`mb-2`}>
                <small>June 2020:</small>
                <br />
                Address to the Waterfront Rotary Club on Social Justice
                Responses to COVID-19
              </h3>
              <Button
                css={tw`mt-auto`}
                to="/downloads/PROFTMROTARYCLUBWATERFRONTSOCJUST120620.pptx"
              >
                Download &rarr;
              </Button>
            </div>
            <div css={tw`p-5 shadow-lg flex flex-col items-start`}>
              <h3 css={tw`mb-2`}>
                <small>June 2020:</small>
                <br />
                Podcast: The ARC Insider - Life after Corruption busting - in
                conversation with South Africa’s Thuli Madonsela
              </h3>
              <Button
                css={tw`mt-auto`}
                to="https://podcasts.apple.com/gb/podcast/the-arc-insider/id1509484109?i=1000478587855"
              >
                Listen now &rarr;
              </Button>
            </div>
          </Grid>
        </div>
      </Section>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    homeHero: file(relativePath: { eq: "home-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    profMadonsela: file(relativePath: { eq: "thuli-madonsela-2022.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    profMadonsela2: file(relativePath: { eq: "about-profmadonsela.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
